import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="products--form"
export default class extends Controller {
  
  static targets = [
    'metaConversionsAccessTokenRow',
    'gtmIdRow',
    'metaPixelIdRow'
  ];

  onMetaConversionsApiEnabledChange = (e) => {
    this.metaConversionsAccessTokenRowTarget.hidden = !e.target.checked;
  }

  onMetaPixelEnabledChange = (e) => {
    this.metaPixelIdRowTarget.hidden = !e.target.checked;
  }

  onGtmEnabledChange = (e) => {
    this.gtmIdRowTarget.hidden = !e.target.checked;
  }
}
